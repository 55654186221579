type OrdinalSuffixMap = Record<Intl.LDMLPluralRule, string>;

type LocaleOrdinalSuffixMap = Record<string, OrdinalSuffixMap>;

export const ORDINAL_NUMBERS_SUFFIX_MAP: LocaleOrdinalSuffixMap = Object.freeze({
    'ca-ES': { zero: '', one: 'r', two: 'n', few: 'r', many: '', other: 'è' },
    'cy-GB': { zero: 'fed', one: 'af', two: 'il', few: 'ydd', many: 'ed', other: 'ed' },
    'de-DE': { zero: '', one: '.', two: '.', few: '.', many: '.', other: '.' },
    'en-US': { zero: '', one: 'st', two: 'nd', few: 'rd', many: '', other: 'th' },
    'es-CO': { zero: '', one: 'º', two: 'º', few: 'º', many: 'º', other: 'º' },
    'es-ES': { zero: '', one: 'º', two: 'º', few: 'º', many: 'º', other: 'º' },
    'fa-IR': { zero: '', one: 'م', two: '', few: '', many: '', other: 'م' },
    'fr-CA': { zero: '', one: 'er', two: '', few: '', many: '', other: 'e' },
    'it-IT': { zero: '', one: '°', two: '°', few: '°', many: '°', other: '°' },
    'nl-NL': { zero: '', one: 'ste', two: 'de', few: 'de', many: 'de', other: 'de' },
    'pt-PT': { zero: '', one: 'º', two: 'º', few: 'º', many: 'º', other: 'º' },
    'sl-SI': { zero: '', one: '.', two: '.', few: '.', many: '.', other: '.' },
    'ur-PK': { zero: '', one: 'واں', two: '', few: '', many: '', other: 'واں' },
    'zh-HANS': { zero: '', one: '第', two: '第', few: '第', many: '第', other: '第' },
    'zu-ZU': { zero: '', one: '', two: '', few: '', many: '', other: '' },
});
