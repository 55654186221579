import Language from '../core/language';

const pdashNamespace = import('../namespaces/pt-pt.pdash.namespace');
const webActivitiesNamespace = import('../namespaces/pt-pt.web-activities.namespace');
const rdashTranslatablesNamespace = import('../namespaces/pt-pt.rdash-translatables.namespace');

class PtPtLanguage extends Language {
    code = 'pt-PT';

    locale = 'pt';

    localName = 'Português';

    englishName = 'Portuguese';

    territory = 'PT';

    timezones = ['Europe/Lisbon'];

    notLoadedNamespaces = {
        pdash: pdashNamespace,
        webActivities: webActivitiesNamespace,
        rdashTranslatables: rdashTranslatablesNamespace,
    };
}

const instance = new PtPtLanguage();
export default instance;
