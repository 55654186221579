export const REQUEST_TIMEOUT_MS = 60000;

export const AUTHENTICATION_SCOPES = {
    ALL: '__all__',
    MODIFY_EMAIL: 'modify_email',
    MODIFY_PHONE_NUMBER: 'modify_phone',
    DELETE_STUDY: 'delete_study',
};

export const AUTHENTICATION_GRANT_TYPES = {
    PASSWORD: 'password',
    REFRESH_TOKEN: 'refresh_token',
};

export const AUTHENTICATION_CLIENT_IDS = {
    WEB: 'web',
};

export const AUTHENTICATION_ENDPOINT = 'v1/auth/token';
export const FORGET_PASSWORD_ENDPOINT = 'v1/auth/reset_password';
export const RESET_PASSWORD_ENDPOINT = 'v1/auth/set_password';
export const CHECK_MFA_ENDPOINT = 'v1/auth/2fa';
export const EDEN_CONFIRMATION_ENDPOINT = 'v1/eden/participation/confirmation';
export const UPDATE_USER_EMAIL_ENDPOINT = 'v1/user/email';
export const UPDATE_USER_PHONE_NUMBER_ENDPOINT = 'v1/user/phone';

// TODO: This should be removed from FE and only rely on Backend's 'retryAfter' header in account locked scenario.
export const ACCOUNT_LOCKED_TIME_IN_MINUTES = 5;
