import { SESSION_STATUSES } from '@edf-pkg/app-activities/src/constants';

import { END_OF_TIME_MS } from '$app-web/constants';

import { PureStore } from './types';

export const storeInitialValue: PureStore = {
    questions: [],
    tokens: {},
    placeholders: {},
    isSandboxMode: false,
    isPublicSurvey: false,
    mediaInteractions: [],
    currentQuestion: { questionId: 0, iteration: null },
    isLoading: true,
    isError: false,
    isSurveyProgressVisible: true,
    captureLocation: false,
    surveyHierarchy: [],
    eligibleCriteria: '',
    location: { lat: null, lng: null, accu: null, speed: null },
    participantRegistrationTime: Date.now(),
    type: 'survey',
    session: {
        activityId: 0,
        deviceId: '',
        sessionUuid: '',
        statusId: SESSION_STATUSES.UNKNOWN,
        studyId: 0,
        tlId: 0,
        tlSessionIndex: 0,
        tlTypeId: 0,
        userId: 0,
        duration: 0,
        expiryTimeMs: END_OF_TIME_MS,
        pTimeMs: 0,
        rTimeMs: 0,
        sTimeMs: 0,
        uuid: '',
        version: 0,
        initialSurveyHierarchy: [],
        inProgressInfo: {},
    },
    isPreviousButtonEnabled: true,
    goNextQuestion: async () => {},
    goToPreviousQuestion: async () => {},
    skipQuestion: async () => {},
    cancelSession: async () => {},
    deleteSession: async () => {},
    addMediaInteraction: () => {},
    updateCurrentQuestion: () => {},
    setLocation: () => {},
    setTranslationId: () => {},
    expireSession: async () => {},
};
