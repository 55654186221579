import { FatalError } from '@edf-pkg/app-error';
import appUtils from '@edf-pkg/app-utils';

import caESLanguage from './ca-es.language';
import cyGBLanguage from './cy-gb.language';
import deDELanguage from './de-de.language';
import enUSLanguage from './en-us.language';
import esCOLanguage from './es-co.language';
import esESLanguage from './es-es.language';
import faIRLanguage from './fa-ir.language';
import frCaLanguage from './fr-ca.language';
import itITLanguage from './it-it.language';
import nlNLLanguage from './nl-nl.language';
import ptPTLanguage from './pt-pt.language';
import slSILanguage from './sl-si.language';
import itPKLanguage from './ur-pk.language';
import zhHansLanguage from './zh-hans.language';
import zuZULanguage from './zu-zu.language';

class Languages {
    languageCodes = {
        [enUSLanguage.code]: enUSLanguage,
        [deDELanguage.code]: deDELanguage,
        [faIRLanguage.code]: faIRLanguage,
        [frCaLanguage.code]: frCaLanguage,
        [itITLanguage.code]: itITLanguage,
        [nlNLLanguage.code]: nlNLLanguage,
        [itPKLanguage.code]: itPKLanguage,
        [esESLanguage.code]: esESLanguage,
        [esCOLanguage.code]: esCOLanguage,
        [caESLanguage.code]: caESLanguage,
        [slSILanguage.code]: slSILanguage,
        [zuZULanguage.code]: zuZULanguage,
        [zhHansLanguage.code]: zhHansLanguage,
        [cyGBLanguage.code]: cyGBLanguage,
        [ptPTLanguage.code]: ptPTLanguage,
    };

    localeToLanguage = {
        [enUSLanguage.locale]: enUSLanguage.code,
        [deDELanguage.locale]: deDELanguage.code,
        [faIRLanguage.locale]: faIRLanguage.code,
        [frCaLanguage.locale]: frCaLanguage.code,
        [itITLanguage.locale]: itITLanguage.code,
        [nlNLLanguage.locale]: nlNLLanguage.code,
        [itPKLanguage.locale]: itPKLanguage.code,
        [esESLanguage.locale]: esESLanguage.code,
        [esCOLanguage.locale]: esCOLanguage.code,
        [caESLanguage.locale]: caESLanguage.code,
        [slSILanguage.locale]: slSILanguage.code,
        [zuZULanguage.locale]: zuZULanguage.code,
        [zhHansLanguage.locale]: zhHansLanguage.code,
        [cyGBLanguage.locale]: cyGBLanguage.code,
        [ptPTLanguage.locale]: ptPTLanguage.code,
    };

    normalizeSupportedLanguage = (languageCode) => {
        const languageCodeSplit = languageCode.split('-');
        let actualLanguageCode = null;
        if (this.languageExist(languageCode)) {
            if (languageCodeSplit.length === 2) {
                const normalizedLanguageCode = `${languageCodeSplit[0].toLowerCase()}-${languageCodeSplit[1].toUpperCase()}`;
                if (this.languageCodeExist(normalizedLanguageCode)) {
                    actualLanguageCode = normalizedLanguageCode;
                } else {
                    actualLanguageCode = this.localeToLanguage[languageCodeSplit[0]];
                }
            } else if (languageCodeSplit.length === 1) {
                if (this.languageLocaleExist(languageCodeSplit[0])) {
                    actualLanguageCode = this.localeToLanguage[languageCodeSplit[0]];
                }
            }
        }

        return actualLanguageCode;
    };

    languageExist = (language) => {
        if (!this.languageCodeExist(language) && !this.languageLocaleExist(language)) {
            const languageSplit = language.split('-');
            if (languageSplit.length === 2) {
                return this.languageLocaleExist(languageSplit[0]);
            }
            return false;
        }
        return true;
    };

    languageCodeExist = (languageCode) => {
        return appUtils.object.hasKey(this.languageCodes, languageCode);
    };

    languageLocaleExist = (languageLocale) => {
        return appUtils.object.hasKey(this.localeToLanguage, languageLocale);
    };

    get = (languageCode) => {
        if (this.languageCodeExist(languageCode)) {
            return this.languageCodes[languageCode];
        }
        throw new FatalError(`Language with given code not exist. languageCode: ${languageCode}`);
    };

    getAll = () => {
        return this.languageCodes;
    };

    getAllLanguageCodes = () => {
        return Object.keys(this.languageCodes);
    };

    snakeCaseNonTranslationKeys = (object, excludedKeys = []) => {
        return appUtils.object.snakeCaseKeys(object, [...this.getAllLanguageCodes(), ...excludedKeys]);
    };

    camelCaseNonTranslationKeys = (object, excludedKeys = []) => {
        return appUtils.object.camelCaseKeys(object, [...this.getAllLanguageCodes(), ...excludedKeys]);
    };
}

const instance = new Languages();
export default instance;
